<template>
  <v-hover v-slot="{hover}">
    <v-card
      class="card-cont mb-10"
      max-width="400"
      :outlined="check ? check : false"
      :style="
        hover ? 'box-shadow: 0px 20px 50px 0px rgba(84, 143, 188, 0.1);' : ''
      "
    >
      <div class="pa-4 img">
        <div style="background: rgba(196, 196, 196, 0.3);padding: 2rem 0;">
          <v-img
            :src="img"
            height="100%"
            :style="
              hover ? 'transform:scale(1.115); transition:0.7s all; ' : ''
            "
          ></v-img>
        </div>
      </div>

      <v-card-title class="justify-center">
        {{ title }}
      </v-card-title>

      <v-card-subtitle class="pt-2">
        {{ content }}
      </v-card-subtitle>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :style="hover ? 'color:#28A84A' : ''"
          text
          :to="link"
          style="    font-size: 11px;
    font-weight: 600;"
        >
          READ MORE <v-icon x-small class="ml-1">mdi-arrow-right</v-icon>
        </v-btn>

        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  name: 'Card',
  props: {
    title: String,
    content: String,
    img: String,
    check: Boolean,
    link: String,
  },
};
</script>

<style scoped lang="scss">
.img {
  border-radius: 4px 4px 0px 0px !important;
}
.card-cont {
  background: #ffffff;
  border-radius: 5px !important;
  border: 0 !important;
  box-shadow: initial;
  filter: drop-shadow(0px 20px 50px rgba(29, 53, 71, 0.1));
  width: 24rem;
  @media (max-width: 768px) {
    width: 22rem !important;
  }
  @media (max-width: 1290px) and (min-width: 769px) {
    width: 23rem;
  }
}
</style>
