<template>
  <div class="case">
    <div>
      <h1 class="text-center top-text ">Cold Chain Equipments</h1>
    </div>
    <v-row class="pa-16 btm-half" style="justify-content:space-evenly">
      <div v-for="(card, i) in cards" :key="i">
        <Card
          :check="true"
          :title="card.title"
          :content="card.content"
          :img="card.img"
          :link="card.link"
        ></Card>
        <v-spacer></v-spacer>
      </div>
    </v-row>
  </div>
</template>

<script>
// import image1 from '../../assets/images/image.png';
import image2 from '../../assets/images/image (16).png';
import image3 from '../../assets/images/image (2).png';
import Card from '../../components/Card';

export default {
  name: 'Product',
  components: {
    Card,
  },
  data: () => ({
    cards: [
      // {
      //   img: image1,
      //   title: 'Gricd ColdBox 15',
      //   content:
      //     'The Gricd Cold Box (mobile refrigerator) is a reusable all-in-one storage tracker for cold chain environmental products monitoring.......',
      // },
      {
        img: image2,
        title: 'Gricd Mote',
        content:
          'Gricd mote is battery-powered and can be stick on any smooth surface, our sensing solutions are adaptable to outdoor and indoor applications.......',
        link: '/cold-chain-equipments/gricd-mote',
      },
      {
        img: image3,
        title: 'Gricd Coldbox 50',
        content:
          'The Gricd Cold Box (mobile refrigerator) is a reusable all-in-one storage tracker for cold chain environmental products monitoring.......',
        link: '/cold-chain-equipments/mote-50',
      },
    ],
  }),

  // Meta data

  metaInfo() {
    return {
      script: [
        {
          type: 'application/ld+json',
          json: {
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                name: 'Home',
                item: 'https://gricd.com/',
              },
              {
                '@type': 'ListItem',
                position: 2,
                name: 'Cold Chain Equipments',
                item: 'https://gricd.com/cold-chain-equipments',
              },
            ],
          },
        },
      ],
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'Cold Chain Equipments',
      // all titles will be injected into this template
      titleTemplate: '%s - Gricd.com',
    };
  },
};
</script>

<style lang="scss" scoped>
.top-text {
  background: rgba(40, 168, 74, 1);
  color: white;
  padding: 5rem 0;
  @media (max-width: 1024px) {
    padding: 3rem 0;
  }
}
.cont {
  justify-content: space-evenly;
}
p {
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 150.8%;
  color: #748494;
}
</style>
